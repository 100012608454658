<template>
  <div>
    <div class="flex flex-col flex-wrap md:flex-row bg-white overflow-hidden">
    <div class="w-full">
    <div class="relative z-10 pb-8 sm:pb-16 md:max-w-2xl md:w-full md:pb-28 mx-auto">
      <div class="mt-0 max-w-screen-xl px-4">
      <div class="max-w-screen-xl mx-auto">
        
        <!-- Begin Mailchimp Signup Form -->
              <link href="//cdn-images.mailchimp.com/embedcode/classic-10_7.css" rel="stylesheet" type="text/css">
              <div id="mc_embed_signup">
              <form action="https://curiousbird.us6.list-manage.com/subscribe/post?u=7075da37c9f887b2a61f32e23&amp;id=f0d5d0fc99" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
              <div id="mc_embed_signup_scroll">
                <p class="pt-5">Would you like to discuss a potential project? Email us at <a href="mailto:chirp@curiousbird.se">chirp@curiousbird.se</a></p>
                <img class="object-cover left-0 mx-auto w-full mt-8 mb-8" src="../assets/images/swallows.png" alt="">
                <p>If you would like to receive occassional emails about our projects, subscribe to our mailing list:</p>
              <div class="indicates-required"><span class="asterisk">*</span> indicates required</div>
              <div class="mc-field-group">
                <label for="mce-EMAIL">Email Address  <span class="asterisk">*</span>
              </label>
                <input type="email" value="" name="EMAIL" class="required email" id="mce-EMAIL">
              </div>
              <div class="mc-field-group">
                <label for="mce-FNAME">First Name </label>
                <input type="text" value="" name="FNAME" class="" id="mce-FNAME">
              </div>
              <div class="mc-field-group">
                <label for="mce-LNAME">Last Name </label>
                <input type="text" value="" name="LNAME" class="" id="mce-LNAME">
              </div>
              <div id="mergeRow-gdpr" class="mergeRow gdpr-mergeRow content__gdprBlock mc-field-group">
                  <div class="content__gdpr">
                      <label class="font-bold">Marketing Permissions</label>
                      <p class="pb-2">Curious Bird AB may contact me by:</p>
                      <fieldset class="mc_fieldset gdprRequired mc-field-group" name="interestgroup_field">
                        <label for="gdpr_67905"><input type="checkbox" id="gdpr_67905" name="gdpr[67905]" value="Y" style="display: inline; width: auto"><span class="ml-2">Email</span></label>
                      </fieldset>
                        <p>You can unsubscribe at any time by clicking the link in the footer of our emails.</p>
                  </div>
                  <div class="content__gdprLegal">
                      <p>We use Mailchimp as our marketing platform. By clicking below to subscribe, you acknowledge that your information will be transferred to Mailchimp for processing. <a href="https://mailchimp.com/legal/" target="_blank">Learn more about Mailchimp's privacy practices here.</a></p>
                  </div>
              </div>
                <div id="mce-responses" class="clear">
                  <div class="response" id="mce-error-response" style="display:none"></div>
                  <div class="response" id="mce-success-response" style="display:none"></div>
                </div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
                  <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_7075da37c9f887b2a61f32e23_f0d5d0fc99" tabindex="-1" value=""></div>
                  <div class="clear">
                    <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button text-white hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-3x font-medium self-center"></div>
                  </div>
              </form>
              </div>
              <!--End mc_embed_signup-->
      </div>
    </div>
  </div>
  </div>
  </div>
  </div>

</template>

<script>
export default {
  name: 'About',

}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  button, .button {
    background-color: #95C11F !important;
    font-weight: bold !important;
    cursor: pointer;
  }
</style>
